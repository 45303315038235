import { IconButton, Menu, MenuItem, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import {
  ABOUT_US_ROUTE,
  HOME_ROUTE,
  HOW_IT_WORKS_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
} from '../../../utils/constants/routes';
import Button from '../../atoms/button/button';
import { HamburgerIcon } from '../../atoms/illustrations/hamburger/hamburger';
import { Logo } from '../../atoms/illustrations/logo/logo';
import './navbar.scss';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: '10.75rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#41415a',
    display: 'flex',
    flexFlow: 'row nowrap',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1rem 2rem',
    '&:focus, &:hover': {
      fontWeight: 700,
    },
  },
}))(MenuItem);

type NavBarProps = {
  className?: string;
};

const NavBar: FC<NavBarProps> = ({ className }) => {
  // Toggle sticky menu on scroll
  const [isSticky, setSticky] = useState(false);
  const handleScroll = () => {
    window.scrollY > 1 ? setSticky(true) : setSticky(false);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handle toggling mobile menu
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClick: MouseEventHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToSignUp = () => {
    window.location.href = SIGN_UP_ROUTE;
    handleClose();
  };

  const navigateToLogIn = () => {
    window.location.href = LOGIN_ROUTE;
    handleClose();
  };

  const navigateToAbout = () => {
    navigate(ABOUT_US_ROUTE);
  };

  const navigateToHowItWorks = () => {
    navigate(HOW_IT_WORKS_ROUTE);
  };

  const containerClass = classNames(
    {
      'sticky bg-gray-50 filter drop-shadow-md': isSticky,
    },
    'hb--navbar top-0 z-50 mb-8',
    className,
  );

  return (
    <nav className={containerClass}>
      <div className="container flex items-center justify-between">
        <div className="flex gap-4 items-center">
          <Link to={HOME_ROUTE}>
            <Logo className="hb--navbar-logo-img absolute" />
            <div className="hb--navbar-logo-text">Hummingbird</div>
          </Link>
          <Button
            variant="text"
            className="hidden lg:block"
            to={HOW_IT_WORKS_ROUTE}
          >
            How it Works
          </Button>
          <Button
            variant="text"
            className="hidden lg:block"
            to={ABOUT_US_ROUTE}
          >
            About Us
          </Button>
        </div>

        <div className="hidden lg:block">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="mr-2.5"
            setMinWidth
            to={LOGIN_ROUTE}
          >
            Log In
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            setMinWidth
            to={SIGN_UP_ROUTE}
          >
            Sign Up
          </Button>
        </div>

        <div className="lg:hidden hb--navbar-mobile-menu">
          <IconButton
            aria-label={Boolean(anchorEl) ? 'Close menu' : 'Open menu'}
            aria-controls="mobile-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <HamburgerIcon showX={Boolean(anchorEl)} />
          </IconButton>
          <Menu
            id="mobile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            elevation={4}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <StyledMenuItem onClick={navigateToHowItWorks}>
              How it Works
            </StyledMenuItem>
            <StyledMenuItem onClick={navigateToAbout}>About Us</StyledMenuItem>
            <StyledMenuItem onClick={navigateToLogIn}>Log In</StyledMenuItem>
            <StyledMenuItem onClick={navigateToSignUp}>Sign Up</StyledMenuItem>
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
